.photo-popup-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(15, 16, 44, 0.5);
  z-index: 1;
  top: 0;
  transition: all 0.3s ease;
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-popup {
  position: relative;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  margin-top: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-popup__image {
  max-width: 800px;
  width: 100%;
  height: 500px;
  object-fit: cover;
  cursor: auto;
}

.photo-popup__button-closed {
  background-image: url(../../images/close-grey.svg);
  background-size: 25px 25px;
  top: -50px;
  left: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  border: none;
  cursor: pointer;
}

.photo-popup__button-closed:hover {
  background-image: url(../../images/close-white.svg);
  background-size: 25px 25px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease, color;
}

.photo-popup__button {
  width: 30px;
  height: 70px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.button-left {
  background-image: url(../../images/photo-gallery/buttons/left-button.svg);
  margin-right: 35px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.button-left:hover {
  background-image: url(../../images/photo-gallery/buttons/left-button-hover.svg);
  transition: 0.15s;
}

.button-left-disabled {
  background-image: url(../../images/photo-gallery/buttons/button-disabled.svg);
  margin-right: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: auto;
}

.button-right {
  background-image: url(../../images/photo-gallery/buttons/left-button.svg);
  margin-left: 35px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: scale(-1, 1);
}

.button-right:hover {
  background-image: url(../../images/photo-gallery/buttons/left-button-hover.svg);
  transform: scale(-1, 1);
  transition: 0.15s;
}

.button-right-disabled {
  background-image: url(../../images/photo-gallery/buttons/button-disabled.svg);
  transform: scale(-1, 1);
  cursor: auto;
  margin-left: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  /* .photo-popup-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .photo-popup {
    max-width: 738px;
  }
  .photo-popup__image {
    max-width: 738px;
    position: relative;
  }
  .button-left {
    position: absolute;
    margin: 0;
    z-index: 2;
    left: 0;
  }
  .button-right {
    position: absolute;
    margin: 0;
    z-index: 2;
    right: 0;
  }
  .photo-popup__button-closed {
    top: -50px;
    left: 710px;
  }
}

@media screen and (max-width: 767px) {
  .photo-popup {
    max-width: 500px;
  }
  .photo-popup__image {
    max-width: 500px;
    height: 510px;
  }
  .photo-popup__button-closed {
    top: -50px;
    left: 475px;
  }
}

@media screen and (max-width: 528px) {
  .photo-popup {
    max-width: 290px;
  }
  .photo-popup__image {
    max-width: 290px;
    height: 275px;
  }
  .photo-popup__button-closed {
    top: -40px;
    left: 265px;
  }
  .photo-popup__button {
    width: 20px;
    height: 50px;
  }
}
