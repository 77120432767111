.personal-data-processing-policy {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 50px 0 50px 0;
}

.personal-data-processing-policy__container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.personal-data-processing-policy__title {
  color: #000;
  margin-bottom: 50px;
}

.personal-data-processing-policy__list {
  list-style: none;
  padding: 0;
}

.personal-data-processing-policy__list-block {
  margin-bottom: 28px;
}

.personal-data-processing-policy__list-item-title {
  color: #000;
  margin-bottom: 25px;
  font-variant-numeric: lining-nums;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 25px;
}

.personal-data-processing-policy__list-num-subparagraphs {
  list-style: none;
  padding: 0;
}

.personal-data-processing-policy__list-num-item {
  color: #000;
  font-variant-numeric: lining-nums;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.27px;
}

.personal-data-processing-policy__list-marked-subparagraphs {
  list-style: none;
  padding: 0;
}

.personal-data-processing-policy__list-marked-item {
  color: #000;
  font-variant-numeric: lining-nums;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.27px;
}

.personal-data-processing-policy__table {
  border-collapse: collapse;
}

.personal-data-processing-policy__table-text {
  color: #000;
  font-variant-numeric: lining-nums;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.27px;
  border: 1px solid #c1c1c1;
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

.personal-data-processing-policy__table-text:nth-child(2n) {
  width: 880px;
}

@media screen and (max-width: 1220px) {
  .personal-data-processing-policy {
    padding-left: 15px;
    padding-right: 15px;
  }

  .personal-data-processing-policy__table-text {
    width: 410px;
  }
}

@media screen and (max-width: 620px) {
  .personal-data-processing-policy__title {
    font-size: 30px;
  }

  .personal-data-processing-policy__list-item-title {
    font-size: 23px;
  }

  .personal-data-processing-policy__table-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 499px) {
  .personal-data-processing-policy__title {
    margin-top: 50px;
  }
}
