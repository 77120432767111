.privacy-policy {
  max-width: 1170px;
  margin: 0 auto;
  padding-bottom: 80px;
}

.privacy-policy__title {
  margin: 50px 0;
}

.privacy-policy__container-item {
  margin-top: 30px;
}

.privacy-policy__container-item-title {
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.privacy-policy__container-item-text {
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 18px;
  font-weight: 500;
  line-height: 124.4%;
  letter-spacing: 0.27px;
}

.privacy-policy__container-item-text-gap {
  margin-top: 19px;
}

@media screen and (max-width: 1200px) {
  .privacy-policy__title {
    padding: 0 15px;
  }
  .privacy-policy__container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 540px) {
  .privacy-policy__title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 90px;
  }
  .privacy-policy__container-item-title {
    font-size: 22px;
  }
}
