.promo {
  width: 100%;
  height: 900px;
  background-image: url("../../images/main-background-image.jpg");
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  position: relative;
}

.promo__container {
  position: relative;
  max-width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo__info {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  z-index: 1;
  top: 310px;
}

.promo__title {
  color: #fff;
  font-family: Raleway;
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
  width: 100%;
  min-height: 215px;
}

.promo__subtitle {
  color: #fff;
  background-color: rgba(8, 9, 10, 0.7);
  border-radius: 13px;
  padding: 10px;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 38px;
  width: 600px;
  min-height: 52px;
}

.promo__button {
  width: 219px;
  height: 56px;
  padding: 15px 25px;
  background-color: #173fc9;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(255, 255, 255, 0.25);
  color: #fff;
  border: none;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.promo__button:hover {
  background: #0733cf;
  box-shadow: 4px 4px 40px 0px rgba(255, 255, 255, 0.25);
  transition: 0.15s;
}

.promo__image {
  position: absolute;
  max-height: 100%;
  width: 670px;
  height: auto;
  top: 140px;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .promo__container {
    padding: 0 0 0 20px;
  }
}

@media screen and (max-width: 1300px) {
  .promo__info {
    top: 270px;
  }
  .promo__image {
    right: 15px;
  }
}

@media screen and (max-width: 1100px) {
  .promo__info {
    top: 270px;
  }
  .promo__image {
    width: 600px;
    top: 217px;
    right: 15px;
  }
}

@media screen and (max-width: 900px) {
  .promo {
    height: 663px;
  }
  .promo__container {
    max-width: 768px;
    padding: 0 15px;
    min-height: 663px;
    height: 100%;
  }
  .promo__info {
    max-width: 378px;
    top: 195px;
  }
  .promo__title {
    font-size: 30px;
    margin-bottom: 25px;
    min-height: 175px;
  }
  .promo__subtitle {
    margin-bottom: 40px;
  }
  .promo__image {
    width: 397px;
    top: 210px;
    right: 15px;
  }
}

@media screen and (max-width: 680px) {
  .promo {
    height: 823px;
  }
  .promo__container {
    max-width: 680px;
    display: flex;
    flex-direction: column;
  }
  .promo__info {
    top: 130px;
    left: 15px;
  }
  .promo__title {
    font-size: 23px;
    margin-bottom: 25px;
    min-height: 135px;
  }
  .promo__image {
    position: static;
    width: 400px;
    margin-left: 70px;
    margin-top: 370px;
  }
  .promo__subtitle {
    width: 400px;
    padding: 0;
  }
}
@media screen and (max-width: 500px) {
  .promo__container {
    padding: 0;
  }
  .promo__info {
    position: static;
    max-width: 290px;
    margin-top: 111px;
  }
  .promo__subtitle {
    width: 290px;
    background-color: initial;
  }
  .promo__image {
    position: static;
    width: 290px;
    margin: 0;
  }
}
