.our-clients__container-carousel {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.our-clients__window-list {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.our-clients__list {
  height: 100%;
  padding: 0;
  display: flex;
  list-style: none;
  transition: translate;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.our-clients__item {
  background-color: white;
  height: 100%;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: 0.4s ease;
  border-right: 1px solid black;
}

@media screen and (max-width: 1180px) {
  .our-clients__item {
    min-width: 255px;
    max-width: 255px;
  }
}

@media screen and (max-width: 767px) {
  .our-clients__item {
    min-width: 200px;
    max-width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .our-clients__item {
    min-width: 166px;
    max-width: 166px;
  }
}

@media screen and (max-width: 515px) {
  .our-clients__item {
    min-width: 195px;
    max-width: 195px;
  }
}

@media screen and (max-width: 400px) {
  .our-clients__container-carousel {
    height: 160px;
  }

  .our-clients__item {
    min-width: 290px;
    max-width: 290px;
  }

  .our-clients__item {
    border-right: none;
  }
}
