.contacts {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 110px 0 80px 0;
}

.contacts__container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.contacts__title {
  color: #000;
  margin-bottom: 50px;
}

.contacts__container-info {
  display: flex;
  border-radius: 20px;
  height: 665px;
  position: relative;
}

.contacts__container-text-info {
  background-color: #0f102c;
  box-sizing: border-box;
  max-width: 550px;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}

.contacts__title-text-info {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.contacts__text-info {
  color: #fff;
  margin: 15px 0 30px 0;
  font-size: 20px;
  line-height: 1.24;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-variant-numeric: lining-nums;
}

.contacts__text-info-link {
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
}

.contacts__text-info-link:hover {
  color: #173fc9;
  border-bottom: 1px solid #173fc9;
  transition: 0.15s;
}

.contacts__text-info-tel {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.24;
  letter-spacing: 0.375px;
}

.contacts__button {
  width: 221px;
  height: 56px;
  margin-top: 20px;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 4px 4px 10px 0px rgba(255, 255, 255, 0.25);
  background-color: #0f102c;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.contacts__button:hover {
  background: #fff;
  box-shadow: 4px 4px 40px 0px rgba(255, 255, 255, 0.25);
  color: #0f102c;
  transition: 0.15s;
}

.contacts__container-map {
  max-width: 650px;
  width: 100%;
  height: 665px;
  position: absolute;
  right: 0;
  border-radius: 20px;
  overflow: hidden;
}

.contacts__map {
  width: 100%;
  height: 665px;
}

@media screen and (max-width: 1220px) {
  .contacts {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1100px) {
  .contacts__container-info {
    display: block;
    height: 100%;
  }

  .contacts__container-text-info {
    max-width: 1050px;
  }

  .contacts__container-items {
    display: flex;
    justify-content: space-around;
    gap: 30px;
  }

  .contacts__container-item {
    width: 324px;
  }

  .contacts__container-map {
    max-width: 1050px;
    width: 100%;
    height: 342px;
    position: relative;
    border-radius: 0 0 20px 20px;
    bottom: 20px;
    z-index: 0;
  }

  .contacts__map {
    width: 100%;
    height: 352px;
  }

  .contacts__button {
    margin-top: 0;
  }

  .contacts__title-text-info-mode {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 550px) {
  .contacts {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .contacts__title {
    font-size: 30px;
  }

  .contacts__container-text-info {
    padding: 15px;
  }

  .contacts__text-info {
    margin-bottom: 40px;
  }

  .contacts__container-items {
    flex-direction: column;
    gap: 0;
  }

  .contacts__container-item {
    width: 100%;
  }

  .contacts__container-map {
    height: 400px;
  }

  .contacts__map {
    height: 400px;
  }
}
