.tooltip-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 16, 44, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 2;
}

.tooltip-popup__container {
  position: relative;
  margin: 0 auto;
  padding: 30px 50px;
  max-width: 502px;
  width: 100%;
  height: 489px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 4px 4px 25px 0px rgba(255, 255, 255, 0.25);
  cursor: auto;
  z-index: 5;
}

.tooltip-popup__image {
  width: 80px;
  height: 80px;
}

.tooltip-popup__text {
  color: #0f102c;
  font-variant-numeric: lining-nums;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: pre-line;
}

.tooltip-popup__close {
  position: absolute;
  top: 30px;
  right: 50px;
  width: 20px;
  height: 20px;
  padding: 0;
  background-image: url(../../images/close-grey.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-color: initial;
  cursor: pointer;
}

.tooltip-popup__close:hover {
  opacity: 0.5;
}

@media screen and (max-width: 545px) {
  .tooltip-popup__container {
    margin: 0 20px;
  }
}

@media screen and (max-width: 400px) {
  .tooltip-popup__container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tooltip-popup__text {
    font-size: 21px;
  }

  .tooltip-popup__close {
    right: 15px;
  }
}
