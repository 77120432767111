@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

html {
  scroll-behavior: smooth;
}

.body {
  overflow-x: hidden;
}

.page {
  margin: 0;
  min-width: 320px;
  box-sizing: border-box;
  font-family: "Raleway", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  padding: 0;
}

button {
  margin: 0;
}

section {
  margin: 0;
  padding: 0;
}

img {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.section-title {
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
