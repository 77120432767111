.photo-gallery {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.photo-gallery__title {
  margin-top: 50px;
  position: absolute;
  left: 0;
}

.photo-gallery__list {
  padding: 0;
  margin: 147px 0 50px 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}

.photo-gallery__list-item {
  width: 170px;
  height: 170px;
}

.photo-gallery__list-item-image {
  max-width: 170px;
  width: 100%;
  height: 170px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
}

.photo-gallery__list-item-image:hover {
  transform: scale(1.05);
  transition: 0.5s;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
}

.photo-gallery__button {
  height: 56px;
  width: 240px;
  padding: 15px 25px;
  background-color: transparent;
  border: 2px solid #173fc9;
  border-radius: 15px;
  color: #173fc9;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto 80px auto;
  cursor: pointer;
}

.photo-gallery__button:hover {
  background-color: #173fc9;
  color: #fff;
  transition: 0.15s;
}

.photo-gallery__button-disabled {
  height: 56px;
  width: 240px;
  padding: 15px 25px;
  background-color: #b2b2b2;
  opacity: 0.5;
  color: #000;
  border: 2px solid #000;
  border-radius: 15px;
  cursor: auto;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto 80px auto;
}

@media screen and (max-width: 1200px) {
  .photo-gallery__title {
    padding-left: 15px;
  }

  .photo-gallery__list {
    grid-template-columns: repeat(5, 170px);
    margin: 147px auto 50px auto;
    width: 970px;
  }
}

@media screen and (max-width: 1000px) {
  .photo-gallery__list {
    grid-template-columns: repeat(4, 170px);
    width: 770px;
  }
}

@media screen and (max-width: 800px) {
  .photo-gallery__list {
    grid-template-columns: repeat(4, 160px);
    width: 738px;
    gap: 32px;
  }

  .photo-gallery__list-item {
    width: 160px;
    height: 160px;
  }

  .photo-gallery__list-item-image {
    max-width: 160px;
    height: 160px;
  }
}

@media screen and (max-width: 750px) {
  .photo-gallery__list {
    grid-template-columns: repeat(3, 160px);
    width: 545px;
    gap: 32px;
  }
}

@media screen and (max-width: 570px) {
  .photo-gallery__list {
    grid-template-columns: repeat(2, 130px);
    width: 290px;
    gap: 30px;
    margin: 135px auto 50px auto;
  }

  .photo-gallery__title {
    font-size: 30px;
  }

  .photo-gallery__list-item {
    width: 130px;
    height: 130px;
  }

  .photo-gallery__list-item-image {
    max-width: 130px;
    height: 130px;
  }
}
