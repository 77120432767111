@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Raleway-Regular.woff2') format('woff2'),
    url('./Raleway-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Raleway-Medium.woff2") format('woff2'),
    url("./Raleway-Medium.woff") format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./Raleway-Bold.woff2') format('woff2'),
    url('./Raleway-Bold.woff') format('woff');
}
