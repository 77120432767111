.nav-bar {
  position: absolute;
  max-width: 768px;
  width: 100%;
  min-height: 623px;
  height: 100%;
  background-color: #fff;
  padding: 0;
  z-index: 2;
  top: 0;
  right: 0;
  position: fixed;
}
.nav-bar__container {
  max-width: 738px;
  height: 100%;
  padding: 0 15px;
  position: relative;
}

.nav-bar__container-header {
  position: absolute;
  max-width: 768px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 3;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.nav-bar__container-header-logo {
  width: 62px;
  padding: 15px 15px 14px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 42px;
}

.nav-bar__container-header-logo-image {
  width: 62px;
  height: 42px;
  cursor: pointer;
}

.nav-bar__container-box {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.nav-bar__container-box-image {
  width: 24px;
  height: 24px;
  margin: 0 15px;
}

.nav-bar__container-box-text {
  font-variant-numeric: lining-nums;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
  margin-left: 13px;
  margin-right: 15px;
  margin: 0;
}

.nav-bar__container-box-text-link {
  color: #000;
}

.nav-bar__container-box-text-link:hover {
  color: #173fc9;
  transition: 0.15s;
}

.nav-bar__container-box-button {
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 22px;
  margin-left: 15px;
}

.nav-bar__container-box-button-closed {
  width: 18px;
  height: 18px;
  border: none;
  background-color: transparent;
  background-image: url("../../images/close-black.svg");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
  cursor: pointer;
  padding: 0;
}

.nav-bar__container-box-button-closed:hover {
  background-image: url("../../images/close-grey.svg");
  transition: 0.15s;
}

.nav-bar__container-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 220px;
  height: 360px;
  padding: 102px 0 0 0;
  gap: 30px;
}

.nav-bar__container-list-item-link {
  color: #000;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.nav-bar__container-list-item-link:hover {
  color: #173fc9;
  transition: 0.15s;
}

.nav-bar__container-list-item-link-active {
  color: #173fc9;
  border-bottom: 2px solid #173fc9;
}

@media screen and (min-width: 769px) {
  .nav-bar {
    display: none;
  }
}

@media screen and (max-width: 499px) {
  .nav-bar__container-header {
    height: 61px;
  }

  .nav-bar__container-header-logo {
    width: 47px;
    padding: 15px 15px 5px 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 42px;
  }

  .nav-bar__container-header-logo-image {
    width: 49px;
    height: 32px;
  }

  .nav-bar__container-box-image {
    margin: 0 8px 0 0;
  }

  .nav-bar__container-box-button {
    margin: 0;
    padding: 0 19px 0 19px;
  }

  .nav-bar__container-box {
    border: none;
  }

  .nav-bar__container-box-text {
    margin-right: 16px;
  }

  .nav-bar__container-list {
    padding: 92px 0 0 0;
  }
}
