.navigation {
  max-width: 1170px;
  height: 18px;
  margin: 0 auto;
  margin-top: 130px;
  display: flex;
}

.navigation__container {
  display: flex;
}

.navigation__link {
  height: 18px;
}

.navigation__link-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #a7a7a7;
}

.navigation__link-text:hover {
  transition: 0.15s;
  color: #173fc9;
}

.navigation__link-text-active {
  color: #000000;
}

.navigation__link-text-active:hover {
  transition: none;
  color: #000000;
}

.navigation__line {
  width: 40px;
  height: 2px;
  background-color: #a7a7a7;
  margin: 8px 20px;
}

@media screen and (max-width: 1200px) {
  .navigation {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 855px) {
  .navigation {
    margin-top: 102px;
  }
}

@media screen and (max-width: 499px) {
  .navigation {
    margin-top: 92px;
  }

  .navigation-column {
    flex-direction: column;
    gap: 20px;
  }
}
