.licenses__container-carousel {
  height: 400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.licenses__container-buttons {
  position: absolute;
  top: 0px;
  right: 0px;
}

.licenses__window-list {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.licenses__list {
  height: 100%;
  padding: 0;
  display: flex;
  transition: translate;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.licenses__button-arrow {
  padding: 0;
  background-color: initial;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.licenses__button-arrow:hover {
  transform: scale(1.2);
  transition: 0.3s;
}

.licenses__button-arrow:disabled {
  transform: scale(1);
  transition: 0s;
  opacity: 0.5;
  cursor: auto;
}

.licenses__button-arrow-left {
  background-image: url("../../images/small-arrow-left.svg");
}

.licenses__button-arrow-right {
  background-image: url("../../images/small-arrow-right.svg");
  margin-left: 30px;
}

@media screen and (max-width: 1180px) {
  .licenses__container-carousel {
    height: 340px;
    max-width: 1000px;
  }
}

@media screen and (max-width: 1040px) {
  .licenses__container-carousel {
    height: 300px;
    max-width: 900px;
  }
}

@media screen and (max-width: 920px) {
  .licenses__container-carousel {
    height: 240px;
    max-width: 737px;
  }
}

@media screen and (max-width: 767px) {
  .licenses__container-carousel {
    height: 260px;
  }

  .licenses__container-buttons {
    top: 360px;
    left: 0;
  }
}

@media screen and (max-width: 627px) {
  .licenses__container-carousel {
    height: 200px;
  }

  .licenses__container-buttons {
    top: 270px;
  }
}

@media screen and (max-width: 485px) {
  .licenses__container-carousel {
    height: 190px;
  }

  .licenses__container-buttons {
    top: 300px;
  }

  .licenses__button-arrow-left {
    background-size: 43px 25px;
  }

  .licenses__button-arrow-right {
    background-size: 43px 25px;
    margin-left: 20px;
  }
}
