.our-services {
  width: 100%;
  min-height: 739px;
  background-color: #fff;
}

.our-services__container {
  width: 1170px;
  margin: 0 auto;
  padding: 110px 0;
}

.our-services__container-title {
  color: #000;
}

.our-services__container-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px 30px;
  margin-top: 50px;
  padding: 0;
}

.our-services__container-item {
  max-width: 230px;
  width: 100%;
  height: 113px;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
}

.our-services__container-item:hover {
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
  transition: 0.15s;
}

.our-services__container-icon {
  width: 30px;
  height: 30px;
}

.our-services__container-text {
  margin-top: 16px;
  max-width: 230px;
  width: 100%;
  height: 63px;
  color: #000;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .our-services__container {
    max-width: 740px;
    padding: 110px 15px;
    margin: 0 auto;
  }

  .our-services__container-list {
    grid-template-columns: repeat(3, 226px);
  }

  .our-services__container-item {
    max-width: 206px;
    height: 108px;
    padding: 10px;
  }

  .our-services__container-text {
    margin-top: 10px;
    max-width: 206px;
  }
}

@media screen and (max-width: 767px) {
  .our-services__container {
    max-width: 485px;
    padding: 110px 15px;
    margin: 0 auto;
  }

  .our-services__container-list {
    grid-template-columns: repeat(2, 226px);
  }
}

@media screen and (max-width: 510px) {
  .our-services__container {
    max-width: 320px;
    padding: 82px 15px;
    margin: 0 auto;
  }

  .our-services__container-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .our-services__container-item {
    max-width: 248px;
    height: 112px;
    padding: 20px;
  }

  .our-services__container-text {
    margin-top: 15px;
    max-width: 230px;
  }

  .our-services__container-title {
    font-size: 30px;
  }
}
