.about-company {
  width: 100%;
  min-height: 743px;
  background-color: #0f102c;
  position: relative;
  overflow: hidden;
  margin-top: -2px;
}

.about-company__container {
  max-width: 1385px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0 0 215px;
}

.about-company__info {
  min-height: 526px;
  max-width: 475px;
  width: 100%;
  padding: 110px 0;
  margin-right: 31px;
}

.about-company__title {
  color: #fff;
}

.about-company__border {
  margin-top: 25px;
  width: 100%;
  border-bottom: 3px solid #fff;
}

.about-company__text {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 124.4%;
  letter-spacing: 0.27px;
  margin-top: 20px;
  max-width: 460px;
}

.about-company__text:last-of-type {
  max-width: 470px;
  width: 100%;
}

.about-company__button {
  width: 221px;
  height: 56px;
  margin-top: 110px;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 4px 4px 10px 0px rgba(255, 255, 255, 0.25);
  background-color: #0f102c;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.about-company__button:hover {
  background: #fff;
  box-shadow: 4px 4px 40px 0px rgba(255, 255, 255, 0.25);
  color: #0f102c;
  transition: 0.15s;
}

.about-company__slider {
  width: 100%;
}

.about-company__slid {
  width: 100%;
  height: 743px;
  background-color: #173fc9;
}

.about-company__slid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 303px;
}

.about-company__slid-container-text {
  max-width: 426px;
  width: 100%;
  min-height: 47px;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto;
}

.about-company__slid-container-button {
  width: 146px;
  height: 60px;
  border-radius: 25px;
  border: 2px solid #fff;
  background-color: #173fc9;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-company__slid-container-button:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
  transition: 0.15s;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  object-fit: cover;
}

@media screen and (min-width: 3000px) {
  .about-company__container {
    left: 50%;
    transform: translateX(-50%);
    margin-right: auto;
  }
}

@media screen and (max-width: 1600px) {
  .about-company__container {
    max-width: 1385px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 215px;
  }
}

@media screen and (max-width: 1385px) {
  .about-company__container {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1279px) {
  .about-company__info {
    min-height: 526px;
    max-width: 738px;
    width: 100%;
    padding: 30px 0 110px 0;
    margin-right: 31px;
  }

  .about-company__border {
    max-width: 277px;
  }

  .about-company__text {
    margin-top: 15px;
    max-width: 738px;
    width: 100%;
  }
}

@media screen and (max-width: 1222px) {
  .about-company {
    min-height: 1050px;
    position: relative;
  }

  .about-company__container {
    flex-direction: column;
    padding: 0 15px 0 60px;
  }

  .about-company__info {
    min-height: 400px;
    max-width: 100%;
    padding: 50px 0 0 0;
  }

  .about-company__border {
    max-width: 277px;
  }

  .about-company__text {
    margin-top: 20px;
    max-width: 850px;
  }

  .about-company__slider {
    position: absolute;
    max-width: 768px;
    width: 100%;
    height: auto;
    top: 550px;
    left: 60px;
  }

  .about-company__slid {
    width: 100%;
    min-height: 300px;
    height: 100%;
  }

  .about-company__slid-container {
    padding-top: 150px;
  }

  .about-company__button {
    margin-top: 40px;
  }
}

@media screen and (max-width: 920px) {
  .about-company {
    min-height: 1100px;
    position: relative;
  }

  .about-company__container {
    flex-direction: column;
    padding: 0 0 0 30px;
  }

  .about-company__info {
    min-height: 326px;
    max-width: 738px;
    padding: 111px 0 110px 0;
  }

  .about-company__text {
    margin-top: 20px;
    max-width: 700px;
    width: 100%;
  }

  .about-company__slider {
    top: 603px;
    left: 15px;
  }

  .about-company__button {
    margin-top: 43px;
  }

  .about-company__slid {
    height: auto;
  }
}

@media screen and (max-width: 720px) {
  .about-company__text {
    max-width: 560px;
  }
}

@media screen and (max-width: 580px) {
  .about-company {
    min-height: 1020px;
  }
  .about-company__container {
    padding: 0 0 0 30px;
  }

  .about-company__info {
    max-width: 450px;
    padding: 80px 0 0 0;
  }

  .about-company__title {
    font-size: 30px;
  }

  .about-company__text {
    margin-top: 25px;
  }

  .about-company__slider {
    max-width: 580px;
    height: 300px;
    top: 720px;
    left: 15px;
  }

  .about-company__slid-container {
    padding-top: 70px;
  }

  .about-company__slid-container-text {
    max-width: 320px;
    min-height: 35px;
    font-size: 30px;
  }

  .about-company__slid-container-button {
    width: 130px;
    height: 54px;
  }
}

@media screen and (max-width: 500px) {
  .about-company {
    min-height: 1030px;
  }

  .about-company__info {
    max-width: 305px;
    padding: 82px 0 0 0;
  }

  .about-company__slider {
    top: 730px;
  }

  .about-company__slid-container {
    padding-top: 70px;
  }

  .about-company__slid-container-text {
    max-width: 268px;
    min-height: 28px;
    font-size: 25px;
  }

  .about-company__slid-container-button {
    width: 130px;
    height: 54px;
  }
}

@media screen and (max-width: 340px) {
  .about-company__text {
    max-width: 290px;
  }
}
