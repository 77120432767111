.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(15, 16, 44, 0.5);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  cursor: pointer;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  position: relative;
  width: 402px;
  height: 440px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 4px 4px 25px 0px rgba(255, 255, 255, 0.25);
  margin: 0 auto;
  cursor: auto;
  text-align: center;
  z-index: 5;
}

.form__closed {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url(../../images/close-grey.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-color: #fff;
  cursor: pointer;
  margin-left: 382px;
}

.form__closed:hover {
  opacity: 0.5;
}

.form__title {
  width: 100%;
  height: 29px;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 47px;
  font-family: Raleway;
}

.form__subtitle {
  width: 380px;
  color: #676767;
  font-size: 18px;
  font-weight: 500;
  line-height: 122.9%;
  letter-spacing: 0.72px;
  margin: 16px auto 0 auto;
  font-variant-numeric: lining-nums;
}

.form__input {
  height: 31px;
  border: none;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 10px 15px;
  color: #000;
  font-variant-numeric: lining-nums;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  margin-top: 30px;
  position: absolute;
  max-width: 372px;
  width: 100%;
  top: 143px;
}

.form__input:last-of-type {
  top: 225px;
}

.form__input:focus {
  border: 2px solid #173fc9;
  outline: none;
}

.form__button-submit {
  width: 402px;
  height: 56px;
  padding: 15px 25px;
  border: none;
  background-color: #173fc9;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  font-family: Raleway;
  cursor: pointer;
  margin-top: 220px;
}

.form__button-submit:hover {
  background-color: #0733cf;
  box-shadow: 4px 4px 40px 0px rgba(255, 255, 255, 0.25);
}

.form__info {
  color: #000;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-top: 15px;
}

.form__link {
  color: #173fc9;
}

.form__link:hover {
  opacity: 0.5;
}

.form__error {
  position: absolute;
  font-variant-numeric: lining-nums;
  margin-top: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #d40000;
  top: 220px;
  left: 55px;
}

.form__error:last-of-type {
  top: 303px;
}

.form__button-disabled {
  background-color: #b2b2b2;
  color: #fff;
  width: 402px;
  height: 56px;
  padding: 15px 25px;
  border: none;
  border-radius: 15px;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  font-family: Raleway;
  margin-top: 220px;
}

@media screen and (max-width: 520px) {
  .form {
    width: 400px;
    padding: 20px 10px 30px 10px;
  }

  .form__info {
    margin-top: 20px;
  }

  .form__closed {
    margin: 0;
    top: 20px;
    right: 15px;
  }

  .form__error {
    top: 210px;
    left: 20px;
  }

  .form__error:last-of-type {
    top: 293px;
  }

  .form__input {
    top: 135px;
  }

  .form__input:last-of-type {
    top: 215px;
  }
}

@media screen and (max-width: 429px) {
  .form {
    width: 290px;
    height: 520px;
  }

  .form__title {
    width: 100%;
    height: 58px;
    margin-top: 47px;
  }

  .form__subtitle {
    width: 210px;
  }

  .form__button-submit {
    width: 290px;
    height: 56px;
    margin-top: 230px;
  }

  .form__button-disabled {
    width: 290px;
    height: 56px;
    margin-top: 230px;
  }

  .form__error {
    font-size: 12px;
    top: 260px;
    left: 12px;
  }

  .form__input {
    top: 183px;
    max-width: 260px;
  }

  .form__input:last-of-type {
    top: 265px;
  }

  .form__error:last-of-type {
    top: 343px;
    left: 12px;
  }
}
