.contacts-page {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 50px 0 63px 0;
}

.contacts-page__container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.contacts-page__title {
  color: #000;
  margin-bottom: 50px;
}

.contacts-page__container-text-info {
  background-color: #0f102c;
  box-sizing: border-box;
  max-width: 1170px;
  padding: 30px;
  display: grid;
  gap: 30px 128px;
  grid-template-columns: 35% 22% 20%;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}

.contacts-page__title-text-info {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.contacts-page__text-info {
  color: #fff;
  margin: 15px 0 0 0;
  font-size: 20px;
  line-height: 1.24;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-variant-numeric: lining-nums;
}

.contacts-page__container-first-text-info {
  max-width: 401px;
  width: 100%;
}

.contacts-page__container-second-text-info {
  max-width: 242px;
  width: 100%;
}

.contacts-page__container-third-text-info {
  max-width: 217px;
  width: 100%;
}

.contacts-page__text-info-link {
  display: inline-block;
  border-bottom: 1px solid #fff;
}

.contacts-page__text-info-link:hover {
  color: #173fc9;
  border-bottom: 1px solid #173fc9;
  transition: 0.15s;
}

.contacts-page__text-info-tel {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.24;
  letter-spacing: 0.375px;
  text-transform: uppercase;
}

.contacts-page__text-info-mail {
  text-transform: uppercase;
}

.contacts-page__button {
  width: 221px;
  height: 56px;
  margin-top: 20px;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 4px 4px 10px 0px rgba(255, 255, 255, 0.25);
  background-color: #0f102c;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  grid-row: 4;
  grid-column: 1;
}

.contacts-page__button:hover {
  background: #fff;
  box-shadow: 4px 4px 40px 0px rgba(255, 255, 255, 0.25);
  color: #0f102c;
  transition: 0.15s;
}

.contacts-page__container-map {
  max-width: 1170px;
  width: 100%;
  height: 500px;
  position: relative;
  bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.contacts-page__map {
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 1220px) {
  .contacts-page {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1190px) {
  .contacts-page__container-text-info {
    gap: 30px 100px;
    grid-template-columns: 30% 25% 20%;
  }
}

@media screen and (max-width: 1057px) {
  .contacts-page__container-text-info {
    gap: 30px 70px;
    grid-template-columns: 28% 30% 25%;
  }
}

@media screen and (max-width: 895px) {
  .contacts-page {
    padding-bottom: 60px;
  }

  .contacts-page__container-text-info {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  .contacts-page__text-production-block {
    grid-row: 2;
    grid-column: 1;
  }

  .contacts-page__text-email-block {
    grid-row: 1;
    grid-column: 2;
  }

  .contacts-page__text-office-block {
    grid-row: 2;
    grid-column: 2;
  }

  .contacts-page__text-accounting-block {
    grid-row: 4;
    grid-column: 1;
  }

  .contacts-page__button {
    grid-row: 4;
    grid-column: 2;
    margin-top: 0;
  }
}

@media screen and (max-width: 620px) {
  .contacts-page {
    padding-bottom: 38px;
  }

  .contacts-page__title {
    font-size: 30px;
  }

  .contacts-page__container-text-info {
    padding: 15px;
    gap: 30px;
    grid-template-columns: 100%;
  }

  .contacts-page__text-production-block {
    grid-row: 3;
  }

  .contacts-page__text-email-block {
    grid-row: 2;
    grid-column: 1;
  }

  .contacts-page__text-office-block {
    grid-row: 4;
    grid-column: 1;
  }

  .contacts-page__text-accounting-block {
    grid-row: 7;
  }

  .contacts-page__button {
    grid-row: 8;
    grid-column: 1;
  }

  .contacts-page__container-map {
    height: 400px;
  }

  .contacts-page__map {
    width: 100%;
    height: 400px;
  }
}
