.error {
  background-color: #fff;
}

.error__container {
  max-width: 400px;
  min-height: 394px;
  margin: 0 auto;
  padding: 246px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error__container-title {
  margin: 0;
  color: #000;
  font-size: 140px;
  font-weight: 400;
  line-height: normal;
  font-variant-numeric: lining-nums;
}

.error__container-text {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.error__container-button {
  text-decoration: none;
  color: #3456f3;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 184px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.error__container-button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1023px) {
  .error__container {
    min-height: 169px;
    padding: 408px 0 222px 0;
  }
}

@media screen and (max-width: 767px) {
  .error__container {
    min-height: 97px;
    padding: 340px 0 30px 0;
  }

  .error__container-title {
    font-size: 80px;
  }

  .error__container-text {
    font-size: 12px;
    padding-top: 10px;
  }

  .error__container-button {
    font-size: 12px;
    margin-top: 284px;
  }
}
