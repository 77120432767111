.licenses {
  width: 100%;
  height: 717px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 110px;
  background-image: url("../../images/licenses-background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.licenses__container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.licenses__title {
  margin-bottom: 50px;
  color: #fff;
}

.licenses__item {
  background-color: white;
  height: 100%;
  min-width: 270px;
  max-width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 15px;
  margin-right: 30px;
  transition: 0.4s ease;
  list-style: none;
}

.licenses__item:hover {
  background-color: #173fc9;
}

.licenses__item:last-child {
  margin-right: 0;
}

.licenses__certificate-img {
  height: 380px;
  width: 250px;
  border-radius: 15px;
}

@media screen and (max-width: 1180px) {
  .licenses {
    padding-right: 15px;
    padding-left: 15px;
  }

  .licenses__container {
    max-width: 1000px;
  }

  .licenses__item {
    min-width: 227px;
    max-width: 227px;
  }

  .licenses__certificate-img {
    height: 320px;
    width: 207px;
  }
}

@media screen and (max-width: 1040px) {
  .licenses__container {
    max-width: 900px;
  }

  .licenses__item {
    min-width: 200px;
    max-width: 200px;
  }

  .licenses__certificate-img {
    height: 280px;
    width: 180px;
  }
}

@media screen and (max-width: 920px) {
  .licenses {
    height: 557px;
    background-image: url("../../images/licenses-small-background.jpg");
  }

  .licenses__container {
    max-width: 737px;
  }

  .licenses__item {
    min-width: 162px;
    max-width: 162px;
  }

  .licenses__certificate-img {
    height: 220px;
    width: 142px;
  }
}

@media screen and (max-width: 767px) {
  .licenses {
    padding-top: 60px;
    height: 557px;
  }

  .licenses__container {
    max-width: 600px;
  }

  .licenses__title {
    margin-bottom: 40px;
  }

  .licenses__item {
    min-width: 185px;
    max-width: 185px;
    margin-right: 20px;
  }

  .licenses__certificate-img {
    height: 240px;
    width: 165px;
  }
}

@media screen and (max-width: 627px) {
  .licenses {
    padding-top: 80px;
    height: 460px;
  }

  .licenses__container {
    max-width: 450px;
  }

  .licenses__title {
    margin-bottom: 30px;
    font-size: 30px;
  }

  .licenses__item {
    min-width: 135px;
    max-width: 135px;
    margin-right: 20px;
  }

  .licenses__certificate-img {
    height: 180px;
    width: 120px;
  }
}

@media screen and (max-width: 485px) {
  .licenses {
    padding-top: 50px;
    height: 460px;
  }

  .licenses__container {
    max-width: 290px;
  }
  
  .licenses__item {
    min-width: 130px;
    max-width: 130px;
    margin-right: 30px;
  }

  .licenses__certificate-img {
    height: 170px;
    width: 115px;
  }
}
