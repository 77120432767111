.header {
  position: absolute;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.header-black {
  border-bottom: 1px solid rgba(000, 000, 000, 0.1);
}

.header__container {
  position: relative;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.header__container-black {
  border-left: 1px solid rgba(000, 000, 000, 0.1);
  border-right: 1px solid rgba(000, 000, 000, 0.1);
}

.header__nav {
  list-style-type: none;
  display: flex;
  gap: 30px;
  align-items: center;
  max-width: 720px;
  width: 100%;
  padding: 0;
  padding-left: 50px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.header__nav-black {
  border-left: 1px solid rgba(000, 000, 000, 0.1);
  border-right: 1px solid rgba(000, 000, 000, 0.1);
}

.header__nav-link {
  text-decoration: none;
  text-transform: uppercase;
  height: 18px;
  padding-bottom: 5px;
}

.header__nav-link-active {
  border-bottom: 2px solid #173fc9;
}

.header__link-text {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}

.header__link-text-active {
  font-size: 15px;
  color: #173fc9;
}

.header__link-text-black {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}

.header__link-text:hover {
  color: #173fc9;
  transition: 0.15s;
}

.header__container-phone {
  max-width: 161px;
  display: flex;
  align-items: center;
  padding: 41px 19px;
}

.header__logo-container {
  margin-top: 16px;
  padding-left: 50px;
  width: 150px;
  height: 84px;
}

.header__logo {
  width: 100px;
  height: 68.254px;
}

.header__container-phone-image {
  height: 24px;
  width: 24px;
}

.header__container-phone-text {
  color: #fff;
  font-variant-numeric: lining-nums;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
  margin-left: 13px;
}

.header__container-phone-text-link {
  color: #fff;
}

.header__container-phone-text-link-black {
  color: #000;
}

.header__container-phone-text-link:hover {
  color: #173fc9;
  transition: 0.15s;
}

.header__container-button {
  display: none;
}

@media screen and (max-width: 1169px) {
  .header__nav {
    padding-left: 30px;
    gap: 20px;
    justify-content: center;
    padding: 0;
  }

  .header__container-phone {
    padding: 41px 20px;
  }

  .header__logo-container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 1120px) {
  .header__nav {
    max-width: 650px;
  }

  .header__container-phone {
    padding: 38px 15px 41px 30px;
  }

  .header__logo-container {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1035px) {
  .header__nav {
    padding-left: 20px;
    max-width: 630px;
    padding: 0 10px;
  }

  .header__container-phone {
    padding: 31px 15px 41px 20px;
  }

  .header__logo-container {
    padding-left: 20px;
    width: 120px;
  }
}

@media screen and (max-width: 985px) {
  .header__nav {
    max-width: 560px;
  }

  .header__container-phone {
    padding: 31px 15px 41px 30px;
  }

  .header__logo-container {
    padding-left: 30px;
  }

  .header__link-text {
    font-size: 13px;
  }

  .header__container-phone-text-link {
    font-size: 13px;
  }
}

@media screen and (max-width: 919px) {
  .header__nav {
    max-width: 530px;
    gap: 14px;
  }

  .header__container-phone {
    padding: 31px 15px 41px 15px;
  }

  .header__logo-container {
    padding-left: 15px;
  }

  .header__link-text {
    font-size: 12px;
  }

  .header__container-phone-text-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 855px) {
  .header {
    height: 70px;
  }

  .header__logo {
    width: 70px;
    height: 50px;
  }
  .header__nav {
    height: 70px;
    gap: 14px;
    max-width: 500px;
  }

  .header__container-phone {
    padding: 28px 15px 20px 40px;
  }

  .header__logo-container {
    padding-left: 15px;
    margin-top: 8px;
    height: 58px;
    width: 80px;
  }

  .header__link-text {
    margin-top: 10px;
    font-size: 12px;
  }

  .header__container-phone-text-link {
    font-size: 12px;
  }

  .header__container-phone-text {
    margin-left: 6px;
  }
}

@media screen and (max-width: 799px) {
  .header__container-phone {
    padding: 28px 15px 20px 10px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 71px;
    padding: 0;
  }

  .header__container {
    max-width: 768px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
  }

  .header__logo-container {
    margin-top: 0;
    padding-left: 0;
    width: 71px;
    height: 42px;
    padding: 15px 6px 15px 0;
    margin-left: 15px;
  }

  .border-white {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  .border-black {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-white_phone {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .border-black_phone {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header__logo {
    width: 62px;
    height: 42px;
  }

  .header__nav {
    display: none;
  }

  .header__container-button {
    display: flex;
    width: 70px;
    min-height: 30px;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    background-repeat: no-repeat;
  }

  .header__container-phone-button {
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .border-button {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header__container-button-white {
    margin-top: 35px;
    background-image: url(../../images/burger-menu-white.svg);
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .header__container-button-black {
    margin-top: 35px;
    background-image: url(../../images/burger-menu-black.svg);
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .header__container-phone {
    max-width: 234px;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
  }
  .header__container-phone-text {
    padding: 0 15px;
    width: 122px;
    height: 18px;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .header__container-phone-text-link {
    display: block;
    width: 122px;
    height: 18px;
    font-size: 15px;
  }
}

@media screen and (max-width: 499px) {
  .header {
    height: 62px;
  }

  .header__logo-container {
    padding: 15px 14px 15px 0;
  }

  .header__logo {
    width: 49px;
    height: 32px;
  }

  .header__container-phone {
    border-left: none;
    height: 62px;
  }

  .header__container-phone-text {
    padding: 0 17px 0 5px;
  }
}
